import { useMetaDataState } from '@/core/features/a-dynamic-page/hooks/meta-data-state';
import { useCookiesMapped } from '@/core/features/app/app-atoms';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { logger } from '@/core/features/logger/logger';
import { fireGeneralTracking } from '@/core/features/tracking/c24-general-tracking';
import { dataLayerPush } from '@/core/features/tracking/google-data-layer';

type ElementType =
    | 'accommodation_cta'
    | 'accommodation_cta_overlay'
    | 'accommodation_tile'
    | 'accommodation_tile_map'
    | 'accommodation_tile_overlay'
    | 'accommodation_tile_overlay_map'
    | 'spot_tile'
    | 'spot_tile_map';

type VerticalType = 'fewo' | 'hotel' | 'pr';

type TrackingConfig = (
    | { activitySpot?: never; city?: never; spot?: string }
    | { activitySpot?: string; city?: string; spot?: never }
) &
    (
        | { elementType: ElementType; linkUrl: string; verticalType?: never }
        | { elementType: ElementType; linkUrl?: never; verticalType?: string }
    ) &
    ({ activity?: never; trackActivities?: true } | { activity?: null | string; trackActivities?: never });

const trackingMap: Record<VerticalType, { actionId: '2288' | '2289' | '2290'; areaId: `linkout_${VerticalType}` }> = {
    fewo: { actionId: '2290', areaId: 'linkout_fewo' },
    hotel: { actionId: '2289', areaId: 'linkout_hotel' },
    pr: { actionId: '2288', areaId: 'linkout_pr' },
} as const;

export default function useLinkoutTracking() {
    const { deviceoutput } = useDeviceoutput();
    const metaData = useMetaDataState();
    const cookiesMapped = useCookiesMapped();
    const activeActivities = useMetaDataState()?.trackingMeta.activity ?? null;

    const trackLinkout = (trackingData: TrackingConfig) => {
        const {
            activity,
            activitySpot,
            city,
            elementType,
            linkUrl,
            spot,
            trackActivities,
            verticalType: type,
        } = trackingData;
        const verticalType = linkUrl ? getVerticalTypeFromLinkUrl(linkUrl) : type;

        if (!isVerticalType(verticalType)) {
            logger.error(
                `Cannot track linkout of invalid verticalType. verticalType: ${verticalType}, linkUrl: ${linkUrl}`,
            );
            return;
        }

        const spotTrackingValue = spot ?? activitySpot ?? city;

        dataLayerPush({
            activity: trackActivities ? activeActivities : activity,
            element: elementType,
            event: 'link',
            verticalType,
            ...(city ? { city } : {}),
            ...(activitySpot ? { activitySpot } : {}),
            ...(spotTrackingValue ? { spot: spotTrackingValue } : {}),
        });

        _trackC24GT(verticalType);
    };

    const _trackC24GT = (verticalType: VerticalType) => {
        if (!metaData) {
            logger.error(`Unable to track data for verticalType: ${verticalType}. metaData is not defined`);
            return;
        }

        const { actionId, areaId } = trackingMap[verticalType];

        fireGeneralTracking({
            cookiesMapped,
            deviceoutput,
            overrides: { actionId, areaId },
            trackingMeta: metaData.trackingMeta,
        });
    };

    return { trackLinkout };
}

const getVerticalTypeFromLinkUrl = (linkUrl: string): VerticalType | null => {
    if (linkUrl.includes('urlaub.check24')) {
        return 'pr';
    }
    if (linkUrl.includes('ferienwohnung.check24')) {
        return 'fewo';
    }
    if (linkUrl.includes('hotel.check24')) {
        return 'hotel';
    }
    logger.error(`cannot get verticalType from linkUrl ${linkUrl}`);
    return null;
};

const isVerticalType = (value: unknown): value is VerticalType => {
    return value === 'pr' || value === 'hotel' || value === 'fewo';
};
